<i18n locale="th" lang="yaml" >
claim.list.see_more : "@:common.see_more"
</i18n>

<template>
	<div class="claim-list">
		<div class="claim-list-topic">
			<div class="topic">{{topic}}</div>
			<div class="see-more">
				<router-link :to="cSeeMore">
					{{$t('claim.list.see_more')}}
				</router-link>
			</div>
		</div>
		<a-list
			:loading="loading"
			item-layout="horizontal"
			class="mycard-ant-list"
			row-key="id"
			:data-source="serviceDataList">
			<template v-slot:renderItem="serviceData">
				<a-list-item>
					<ServiceLiteCard
						:key="serviceData.id"
						view-mode="claim"
						:service-data="serviceData"/>
				</a-list-item>
			</template>
		</a-list>
	</div>
</template>

<script>
import {List} from "ant-design-vue"
import ServiceLiteCard from "@components/service/ServiceLiteCard.vue"
import axios from "axios"
import ApiError from "@utils/errors/ApiError"
import {fulfillServiceCardData} from "@utils/objectUtil"
import LoginInfoMixin from "@mixins/LoginInfoMixin.vue"

export default {
	components : {
		ServiceLiteCard,
		"a-list" : List, "a-list-item" : List.Item,
	} ,
	mixins : [LoginInfoMixin] ,
	props : {
		topic : {
			type : String,
			default : "Claim",
		} ,
		mode :{
			type : String,
			default : 'prepare' ,
		} ,
		maxDisplay : {
			type : Number,
			default : 5
		} ,
		showOnlyMyCompany : {
			type : Boolean,
			default : false
		} ,
		maxAges : {
			type : Number,
			default : -1
		}
	} ,
	data() {
		return {
			loading : false,
			error : undefined,
			serviceDataList : []
		}
	} ,
	computed : {
		cSeeMore() {
			return {name:"report/claim_request",query:{claim_mode : this.mode}}
		} ,
		sortField() {
			switch(this.mode) {
				case 'wait-decision' : return 'requeted_datetime'
				case 'decision' : return 'decision_datetime'
				default :  return 'updated_datetime'
			}
		}
	} ,
	watch : {
		showOnlyMyCompany() {
			this.fetchData()
		}
	} ,
	mounted() {
		this.fetchData()
	} ,
	methods : {
		fetchData() {
			this.loading = true
			this.error = undefined
			const search = {
				page_size : this.maxDisplay,
				current_page : 1,
				sort_field : this.sortField,
				sort_order : "descend" ,
			}
			const filter = {
				mode : this.mode
			}
			if (this.maxAges > 0) {
				filter.max_ages = this.maxAges
			}
			if (this.showOnlyMyCompany) {
				filter.company_id = this.currentCompany.id
			}

			axios.get("/api/services/search-claim-request",{params : {search,filter}}).then((response)=>{
				const bucket = response.data.data.bucket

				this.serviceDataList = response.data.data.services.map((s)=>{
					return fulfillServiceCardData(s,bucket)
				})
			}).catch((error) => {
				this.error = ApiError.getDisplayErrorMessage(this,error)
			}).finally(()=>{
				this.loading = false
			})
		}
	}
}
</script>

<style lang="less" scoped>
.claim-list::v-deep {
	.service-card.mycard {
		padding : 8px 0 ;
		width : 100%;
		border : none;
		max-width : 485px;
	}
}
.claim-list-topic {
	position : relative;
	margin-bottom : 8px;
	display: flex;
	.topic {
		font-family: @font-family-title;
		font-size : 1.2em;
		margin-right: 10px;
	}
	.see-more {
		font-size : 0.95em;
		text-decoration: underline dashed;
		align-self: center;
	}
}
.mycard-ant-list.ant-list::v-deep {
	min-height : 80px;
	.ant-empty {
		margin : 8px 0;
	}
	> .ant-spin-nested-loading {
		min-height : 80px;
	}
}
</style>
