<i18n locale="th" lang="yaml" >
page.title : "Dashboard"
drone.search.input.label : "S/N"
drone.search.input.placeholder : "ระบุ S/N ของ Drone"
drone.ticket.create.btn : "สร้าง Ticket ใหม่"
drone.service.create.btn : "สร้างใบซ่อมใหม่"
</i18n>

<template>
	<div class="page page-padding page-fit-tablet">
		<my-page-header :title="$t('page.title')"/>
		<div class="drone-search-pane">
			<DroneSearchPanel ref="searchFilterRef" @submit-native-search="handleNativeSearch"/>
			<div class="create-pane">
				<a-button type="info" @click="openTicketCreate">
					{{$t('drone.ticket.create.btn')}}
				</a-button>
				<a-button type="info"  @click="openServiceCreate">
					{{$t('drone.service.create.btn')}}
				</a-button>
			</div>
		</div>
		<div v-if="isShowDroneTable">
			<a-card :bordered="false">
				<DroneTable
					bordered
					:show-pixhawk="true"
					:row-key="record => record.id"
					:data-source="droneDataList"
					:pagination="pagination"
					:loading="loading"
					:hide-register="true"
					:hide-status="true"
					:hide-firmware="true"
					:hide-phone="false"
					:hide-email="false"
					@change="handleTableChangePage"/>
			</a-card>
		</div>
		<div v-else>
			<Dashboard :is-manangement="isManagementCompany" />
		</div>
	</div>
</template>

<script>
import DroneSearchPanel from "@components/drone/DroneSearchPanel.vue"
import axios from "axios"
import ApiError from "@utils/errors/ApiError"
import DroneTable from "@components/drone/DroneTable.vue"
import {fulfillDrone} from "@/src/utils/objectUtil"
import LoginInfoMixin from "@mixins/LoginInfoMixin.vue"
import Dashboard from "@components/dashboard/Dashboard.vue"

export default {
	components: {
		DroneSearchPanel,
		DroneTable,
		Dashboard,
	},
	page() {
		return {
			title: this.$t('page.title'),
		}
	},
	mixins : [LoginInfoMixin] ,
	data() {
		return {
			isShowDroneTable: false,
			droneDataList: [],
			user: undefined,
			userCompany: undefined,
			pagination: {
				pageSize: 20 ,
				current: 1,
				total: 0,
				hideOnSinglePage: true,
			},
			sort: {
				field: 'serial_no',
				order: 'ascend',
			},
			loading: false
		}
	},
	computed : {
		isManagementCompany() {
			return this.$isManagement(this.currentCompany.company_type)
		}
	} ,
	methods : {
		openTicketCreate() {
			this.$open({name : "ticket/create"})
		} ,
		openServiceCreate() {
			this.$open({name : "service/create"})
		},
		callSearchDrone(toPage = null, keyword, searchType) {
			if (toPage === null)
				toPage = this.pagination.current
			const search = {
				page_size : this.pagination.pageSize ,
				current_page : toPage,
				sort_field : this.sort.field ,
				sort_order : this.sort.order ,
			}
			const filter = {
				keyword : keyword,
				search_type: searchType,
			}
			this.loading = true
			axios.get("/api/drones/search",{params : { search, filter }}).then((response)=>{
				const page = response.data.data.pagination
				const dataBucket = response.data.data.bucket

				this.droneDataList = response.data.data.drones.map((drone)=>{
					return fulfillDrone(drone, dataBucket)
				})
				this.isShowDroneTable = true

				this.pagination.current = toPage
				this.pagination.total = page.total
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			}).finally(()=>{
				this.loading = false
			})
		},
		handleNativeSearch (searchFilter) {
			this.pagination = {
				pageSize: 20 ,
				current: 1,
				total: 0,
				hideOnSinglePage: true,
			}
			this.callSearchDrone(null, searchFilter.keyword, searchFilter.search_type)
		},
		handleTableChangePage(pagination, filters, sorter) {
			const searchFilter = this.$refs.searchFilterRef.getSearchFilter()
			this.sort.field = sorter.columnKey
			this.sort.order = sorter.order
			this.callSearchDrone(pagination.current, searchFilter.keyword, searchFilter.search_type)
		},
	}
}
</script>
<style lang="less" scoped>
.drone-search-pane {
	display: flex;
	flex-wrap: wrap;
	padding : 12px 16px;
	border : 1px solid @white;
	border-radius: @border-radius-base;
	box-shadow : @shadow-1-right;
	background : @white;
	margin-bottom : 24px;
	> label {
		line-height: 32px;
		vertical-align: middle;
		margin-right : 2px;
		color : @info-color;
	}
	.create-pane {
		display : inline-block;
		margin-left : 16px;

		.mobile & {
			display : block;
			margin-left : 0;
			margin-top : 8px;
		}
	}
}
</style>
