<i18n locale="th" lang="yaml" >
ticket.field.company : "โดย"
ticket.field.report_by : "ผู้แจ้ง : {name}"
ticket.field.event_date : "เมื่อ"
ticket.field.general_ticket.short : "ทั่วไป"
ticket.field.resolved_result.prefix : "ผลวิเคราะห์ : "
ticket.field.status : "สถานะ : {status}"
</i18n>

<template>
	<div class="ticket-lite-list-card">
		<div class="ticket-lite-list-first">
			<span v-if="!ticket.drone_id" class="drone-name-general">
				{{$t('ticket.field.general_ticket.short')}}
			</span>
			<my-router-link v-else name="drone/view" :param-value="drone.serial_no" param-name="serial_no" class="drone-name" :title="drone.name">
				{{drone.serial_no}}
			</my-router-link>
			<my-router-link name="ticket/view" :param-value="ticket.id" class="topic-link"
				:title="ticket.topic">
				{{ticket.ticket_no}} : {{ticket.topic}}
			</my-router-link>
		</div>
		<div class="ticket-lite-list-detail">
			<div class="left ticket-status-list">
				<div class="ticket-problem-type">
					<a-tag class="mytag mytag-dash mytag-tiny">
						{{$tenum('ticket.problem_type',ticket.problem_type)}}
					</a-tag>
				</div>
				<div v-if="!hideStatus" class="ticket-status">
					{{$t('ticket.field.status',{ status: $tenum('ticket.status',ticket.status + '.description') })}}
				</div>

				<div v-if="ticket.problem_type == 'consult' && $notEmpty(ticket.resolved_result)">
					{{$t('ticket.field.resolved_result.prefix')}}
					{{$tenum('ticket.resolved_result',ticket.resolved_result)}}
				</div>
			</div>
			<div class="right ticket-open-details">
				<template v-if="!hideCompany">
					<span class="image-text-very-tiny">
						{{$t('ticket.field.company')}}
					</span>
					<ImageName :name="company.name" :src="company.image_url"
						class="ticket-field-company"
						size="very-tiny">
						<a-icon type="shop" />
					</ImageName>
				</template>

				<DayFromNowText
						class="image-text-very-tiny"
						:prefix="$t('ticket.field.event_date')"
						:additional-tooltip="$t('ticket.field.report_by',{ name: reportBy.username })"
						:value="ticket.event_datetime" />
			</div>
		</div>
	</div>
</template>

<script>
import {Tag} from "ant-design-vue"
import DayFromNowText from "@components/common/DayFromNowText.vue"
import {emptyTicket} from "@utils/objectUtil"
import ImageName from "@components/common/ImageName.vue"
export default {
	components : {
		DayFromNowText,  ImageName ,
		"a-tag" : Tag,
	},
	props : {
		ticketData : {
			type : null,
			default : () => {
				return emptyTicket()
			}
		} ,
		hideCompany : {
			type : Boolean ,
			default : false,
		} ,
		hideStatus : {
			type : Boolean,
			default : false
		}
	} ,
	computed : {
		drone() {
			return this.ticketData.droneData.drone
		} ,
		ticket() {
			return this.ticketData.ticket
		} ,
		company() {
			return this.ticketData.reportBy.company
		} ,
		reportBy() {
			return this.ticketData.reportBy.user
		}
	}
}
</script>

<style lang="less" scoped>
.ticket-lite-list-card {
	padding : 8px 0;
	width : 100%;
}
.ticket-lite-list-first {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	.drone-name {
		display : inline-block;
		padding : 2px 6px;
		font-size : 0.75em;
		border : 1px dashed @primary-2;
		color : @primary-color;
		margin-right : 2px;
		border-radius: 8px;
	}
	.drone-name-general {
		display : inline-block;
		padding : 2px 6px;
		font-size : 0.75em;
		background-color : @yellow-8;
		color : @white;
		margin-right : 2px;
		border-radius: 8px;
	}
	.topic-link {
		font-size : 0.95em;
	}
}
.ticket-lite-list-detail {
	display : flex;
	flex-wrap: wrap;
	color : @text-muted;
	padding-top : 12px;
	align-content: flex-end;
	.left {
		display : block;
		margin-right : 2px;
		align-self: flex-end;
	}
	.right {
		display: block;
		text-align: right;
		flex-grow: 1;
		align-self: flex-end;
	}
	.ticket-status-list {
		font-size : 0.9em;;
	}
	.ticket-open-details {
		font-size : 0.8em;
	}
}

.ticket-field-company {
	color : @primary-color;
}
</style>
