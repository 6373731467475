<i18n locale="th" lang="yaml" >
section.services.refer : "งานซ่อมที่ถูกส่งต่อมาให้ {name} (Refer Service)"
section.services : "งานซ่อมของ {name}"
see_more.label : "@:common.see_more"
</i18n>

<template>
	<div>
		<div v-show="referServiceDataList.length > 0" class="dashboard-pane service-refer-pane">
			<div class="page-section-title">
				{{$t('section.services.refer',{ name: companyName })}}
				<router-link to="/dashboard/refer-work" class="service-section-action">
					{{$t('see_more.label')}}
				</router-link>
			</div>
			<MyCardList
				class="mycard-list-grid service-refer-list"
				:loading="referLoading">
					<ServiceLiteCard v-for="serviceData of referServiceDataList"
						:key="serviceData.id"
						:service-data="serviceData"/>
			</MyCardList>
		</div>

		<div class="dashboard-pane service-pane">
			<div class="page-section-title">
				{{$t('section.services',{ name: companyName })}}
			</div>

			<ServiceSummaryPage
				class="page-padding-excluded"
				filter-name="company_id"
				:filter-value="currentCompany.id"/>
		</div>
	</div>
</template>

<script>
import MyCardList from "@components/common/MyCardList.vue"
import ServiceLiteCard from "@components/service/ServiceLiteCard.vue"
import ServiceSummaryPage from "@views/service/ServiceSummaryPage.vue"
import LoginInfoMixin from "@mixins/LoginInfoMixin.vue"
import axios from "axios"
import ApiError from "@utils/errors/ApiError"
import {fulfillServiceCardData} from "@utils/objectUtil"
export default {
	components : {
		ServiceSummaryPage , ServiceLiteCard,
		MyCardList ,
	} ,
	mixins : [LoginInfoMixin],
	data() {
		return {
			referServiceDataList : [] ,
			referLoading : false,
		}
	} ,
	computed : {
		companyName() {
			return this.currentCompany.name
		}
	} ,
	mounted(){
		this.fetchData()
	} ,
	methods : {
		fetchData() {
			this.fetchWaitReferService()
		} ,
		fetchWaitReferService() {
			this.referLoading = true
			const search = {
				page_size : 5 ,
				current_page : 1,
			}
			const filter = {
				status_list : ['close'] ,
				mode : 'wait-refer' ,
				refer_to_company_id : this.currentCompany.id
			}

			axios.get("/api/services/search",{params : {search,filter}}).then((response)=>{
				const bucket = response.data.data.bucket

				this.referServiceDataList = response.data.data.services.map((s)=>{
					return fulfillServiceCardData(s,bucket)
				})
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			}).finally(()=>{
				this.referLoading = false
			})
		} ,
	}
}
</script>
<style lang="less">
@import './dashboard.less';
.page-section-title {
	.service-section-action {
		font-family: @font-family;
		text-decoration: underline dashed;
		font-size: 14px;
		font-weight: 200;
	}
}
</style>
