<i18n locale="th" lang="yaml" >
stat.service.num_close.title : "งานซ่อม"
stat.service.num_close.suffix : "งาน"
stat.service.num_close.previous : "เดือนที่แล้วมี"

stat.service.man_hours.title : "Man-hours"
stat.service.man_hours.previous : "เดือนที่แล้วใช้"

stat.service.payment.title : "รายได้"
stat.service.payment.previous : "เดือนที่แล้วได้"
</i18n>

<template>
	<div class='service-stat-container'>
		<div class='stat-card'>
			<div class='stat-title'>
				{{$t('stat.service.num_close.title')}}
			</div>
			<div class="stat-value">
				<span class="value">{{current.num_services | integer | displayValue(done)}}</span>
				<span class="suffix">{{$t('stat.service.num_close.suffix')}}</span>
			</div>
			<div class="stat-previous">
				(
				{{$t('stat.service.num_close.previous')}}
				{{previous.num_services | integer | displayValue(done)}}
				{{$t('stat.service.num_close.suffix')}}
				)
			</div>
		</div>

		<div class='stat-card'>
			<div class='stat-title'>
				{{$t('stat.service.man_hours.title')}}
			</div>
			<div class="stat-value">
				<span class="value">{{current.man_hours | price(true) | displayValue(done) }}</span>
				<span class="suffix">{{$t('common.hour.short')}}</span>
			</div>
			<div class="stat-previous">
				(
				{{$t('stat.service.man_hours.previous')}}
				{{previous.man_hours | price(true) | displayValue(done)}}
				{{$t('common.hour.short')}}
				)
			</div>
		</div>

		<div class='stat-card'>
			<div class='stat-title'>
				{{$t('stat.service.payment.title')}}
			</div>
			<div class="stat-value">
				<span class="value">{{current.amount | price(true) | displayValue(done)}}</span>
				<span class="suffix">{{$t('common.currency.unit')}}</span>
			</div>
			<div class="stat-previous">
				(
				{{$t('stat.service.payment.previous')}}
				{{previous.amount | price(true) | displayValue(done)}}
				{{$t('common.currency.unit')}}
				)
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios"
import ApiError from "@utils/errors/ApiError"
export default {
	filters : {
		displayValue : function(value,done) {
			if (done) {
				return value
			} else {
				return "---"
			}
		}
	} ,
	data() {
		return {
			current : {} ,
			previous : {} ,
			done : false
		}
	} ,
	mounted() {
		this.fetchData()
	} ,
	methods : {
		fetchData() {
			this.done = false
			axios.get("/api/services/stat-dashboard").then((response)=>{
				this.current = response.data.data.current
				this.previous = response.data.data.previous
				this.done = true

			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			})
		} ,
	}
}
</script>

<style lang="less" scoped>
.service-stat-container {
	display : flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	padding-bottom : 24px;
}
.stat-card {
	flex-basis : 300px;
	min-width: 0;
	padding : 28px 32px;
	background : @white;
	font-family: @font-family-title;

	.mobile & {
		padding : 28px 16px;
		font-size : 0.9em;
		border-right : 1px solid @border-color-base;
		&:last-child {
			border-right : none;
		}
	}
}
.stat-title {
	color : @text-muted;
	font-size : 0.95em;
	margin-bottom : 8px;
}
.stat-value {
	margin-bottom : 4px;
	.value {
		color : @primary-color;
		font-size : 2em;
		margin-right : 4px;

		.mobile & {
			font-size : 1.7em;
			margin-right : 0;
		}
	}
	.suffix {
		color : @text-muted;
		font-size : 0.9em;
	}
}
.stat-previous {
	color : @text-muted;
	font-size : 0.85em;
	.mobile & {
		font-size : 0.75em;
	}
}

</style>
